import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import { colors } from '~/utils/tailwindUtils';
import { FontWeight } from '~/constants';

interface CustomButtonProps extends ButtonProps {
  mode?: 'transparent' | 'basic' | 'light' | 'link';
  withBorder?: boolean;
  smaller?: boolean;
}

const Button = React.forwardRef(function Button(
  {
    children,
    mode = 'transparent',
    withBorder,
    smaller = false,
    ...rest
  }: PropsWithChildren<CustomButtonProps>,
  ref: React.Ref<HTMLButtonElement>
) {
  const commonProps = {
    fontSize: '1rem',
    fontWeight: FontWeight.Bold,
    lineHeight: '1.188rem',
  };

  let customProps;
  switch (mode) {
    case 'transparent':
      customProps = {
        ...commonProps,
        bg: 'transparent',
        color: 'black',
        border: withBorder ? '1px solid' : '',
        height: smaller ? '32px' : undefined,
        borderColor: 'black',
        _hover: {
          bg: colors.gray[100],
        },
      };
      break;
    case 'basic':
      customProps = {
        ...commonProps,
        bg: 'brand-red.500',
        color: 'white',
        _hover: {
          bg: 'red.800',
        },
      };
      break;
    case 'light':
      customProps = {
        ...commonProps,
        bg: 'red.100',
        color: 'red.600',
        _hover: {
          color: 'red.700',
          bg: 'red.200',
        },
      };
      break;
    case 'link':
      customProps = {
        ...commonProps,
        padding: 0,
        bg: 'transparent',
        borderRadius: 0,
        borderBottom: '1px solid var(--chakra-colors-gray-800);',
        height: 'fit-content',
        _hover: {
          color: 'brand-red.500',
          borderColor: 'brand-red.500',
        },
        _active: {
          bg: 'transparent',
        },
      };
      break;

    default:
      customProps = {
        ...commonProps,
        bg: 'brand-red.500',
        color: 'white',
        _hover: {
          bg: 'red.800',
        },
      };
      break;
  }

  const props = { ...customProps, ...rest };

  return (
    <ChakraButton borderRadius={20} ref={ref} {...props}>
      {children}
    </ChakraButton>
  );
});

export default Button;
