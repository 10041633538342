import { ProductCategory } from '~/utils/claimUtils';
import { ParamValueTypeForKeyProperties } from '../app/[locale]/home/store/[category]/[id]/_components/ProductKeyproperties';
import { IconName } from '~/components/core/Icon';

export type BasePriceType = {
  unit?: number;
  pallet?: number;
  container?: number;
  metre?: number;
  piece?: number;
};

export type ProductpriceType = {
  discount?: {
    label: string;
    price: BasePriceType;
  };
} & BasePriceType;

export type RelatedLink = {
  href: string;
  templated: boolean;
};

export type RelatedLinks = {
  [key: string]: RelatedLink;
};

export type ParamValueType = {
  value?: string;
  parameter?: string;
  parameterId?: string;
  unitSymbol?: string | null;
  unitSymbolRequired?: boolean;
  _links: RelatedLinks;
};

export type ProductType = {
  status?: string;
  id: string;
  name: string;
  brand?: string;
  productImage?: ParamValueType;
  price?: ProductpriceType | ParamValueType;
  flag?: string;
  category: {
    id: ProductCategory | string;
    name: string;
  };
  keyFeatures?: ParamValueTypeForKeyProperties[];
  documents?: ParamValueType[];
  details?: ParamValueType[];
  measurements?: ParamValueType[];
  warrantyDetails?: ParamValueType[];
  description?: ParamValueType;
  parameterValues?: ParamValueType[];
  deliveryDetails?: ParamValueType[];
  recommended?: boolean;
};

export enum ProductKeyproperties {
  ModuleType = IconName.ModuleType,
  OutputPower = IconName.OutputPower,
  PanelEfficiency = IconName.PanelEfficiency,
  CellType = IconName.CellType,
  Colour = IconName.Colour,
  CableType = IconName.CableType,
  NumberOfPhases = IconName.NumberOfPhases,
  InverterPower = IconName.InverterPower,
  MaxDCVoltage = IconName.MaxDCVoltage,
  AcPowerRange = IconName.AcPowerRange,
  BatteryVoltage = IconName.BatteryVoltage,
  Warranty = IconName.Warranty,
  Capacity = IconName.Capacity,
  Length = IconName.Length,
  InverterType = IconName.InverterType,
  ModulePower = IconName.ModulePower,
  CableColour = IconName.Cables,
}

export const KeyPropIconName = {
  de: {
    'Modul-Typ': ProductKeyproperties.ModuleType,
    'Primär (Jahre, % Ausgangsleistung)': ProductKeyproperties.OutputPower,
    'Wirkungsgrad des Panels (%) bei STC': ProductKeyproperties.PanelEfficiency,
    'Max. Gleichspannung': ProductKeyproperties.MaxDCVoltage,
    'AC-Leistungsbereich': ProductKeyproperties.AcPowerRange,
    'Battery Voltage': ProductKeyproperties.BatteryVoltage,
    Garantie: ProductKeyproperties.Warranty,
    Wechselrichtertyp: ProductKeyproperties.InverterType,
    Modulleistung: ProductKeyproperties.ModulePower,
    Zellentyp: ProductKeyproperties.CellType,
    Kabelfarbe: ProductKeyproperties.CableColour,
    Farbe: ProductKeyproperties.Colour,
    Kabeltyp: ProductKeyproperties.CableType,
    Phasenanzahl: ProductKeyproperties.NumberOfPhases,
    Wechselrichterleistung: ProductKeyproperties.InverterPower,
    Kapazität: ProductKeyproperties.Capacity,
    Länge: ProductKeyproperties.Length,
  },
  en: {
    'Inverter Type': ProductKeyproperties.InverterType,
    'Module Power': ProductKeyproperties.ModulePower,
    'Module Type': ProductKeyproperties.ModuleType,
    'Primary (Years, % Output Power)': ProductKeyproperties.OutputPower,
    'Panel Efficiency (%) At STC': ProductKeyproperties.PanelEfficiency,
    'Cell Type': ProductKeyproperties.CellType,
    'Cable colour': ProductKeyproperties.CableColour,
    'Cable type': ProductKeyproperties.CableType,
    'Number of phases': ProductKeyproperties.NumberOfPhases,
    'Inverter Power': ProductKeyproperties.InverterPower,
    'Max. DC voltage': ProductKeyproperties.MaxDCVoltage,
    'Series AC power range': ProductKeyproperties.AcPowerRange,
    'Battery Voltage': ProductKeyproperties.BatteryVoltage,
    Warranty: ProductKeyproperties.Warranty,
    Colour: ProductKeyproperties.Colour,
    Capacity: ProductKeyproperties.Capacity,
    Length: ProductKeyproperties.Length,
  },
};

/**
 * Returns product category names as used in the Backend
 * - Inverters = 'PV Inverters'
 * - Modules = 'PV Modules'
 * - Batteries = 'PV Batteries'
 * - Other = 'Other'
 */
export enum ProductCategoryBEnum {
  Inverters = 'PV Inverters',
  Modules = 'PV Modules',
  Batteries = 'PV Batteries',
  Other = 'Other',
}

/**
 * Returns product category names as used in the Frontend
 * - Inverters = 'inverters'
 * - Modules = 'modules'
 * - Batteries = 'batteries'
 * - Other = 'cables'
 */
export enum ProductCategoryFEnum {
  Inverters = 'inverters',
  Modules = 'modules',
  Batteries = 'batteries',
  Cables = 'cables',
}

export type ProductCardType = {
  id: string;
  name: string;
  brand?: string;
  price?: ProductpriceType;
  category: {
    id: string;
    name?: ProductCategory | null;
  };
  flag?: string;
  productImage?: { value?: string };
};

/**
 * Returns possible price units
 * - Container = 'container'
 * - Piece = 'piece'
 * - Metre = 'metre'
 * - UnitEnum = 'unit'
 * - Palet = 'pallet'
 */
export enum PriceUnitEnum {
  Container = 'container',
  Piece = 'piece',
  Metre = 'metre',
  UnitEnum = 'unit',
  Palet = 'pallet',
}

/**
 * Returns supported param values for sorting product data
 * - EarliestDelivery = 'earliestDelivery'
 * - LowToHigh = 'lowToHigh'
 * - HightToLow = 'highToLow'
 * - AtoZ = 'name,asc'
 * - Draft = 'Draft'
 */
export enum DropDownvaluesEnum {
  EarliestDelivery = 'earliestDelivery',
  LowToHigh = 'lowToHigh',
  HightToLow = 'highToLow',
  AtoZ = 'name,asc',
  ZtoA = 'name,desc',
  Draft = 'Draft',
}

export type CategoryType = {
  name: string;
  parent: {
    href: string;
    templated: boolean;
  } | null;
  id: string;
  children: [];
  _links: {
    [key: string]: string;
  };
};

export type SupportedCategoriesType = {
  [ProductCategoryFEnum.Batteries]: {
    id: string;
    value: string;
    label: string;
  };
  [ProductCategoryFEnum.Modules]: { id: string; value: string; label: string };
  [ProductCategoryFEnum.Inverters]: {
    id: string;
    value: string;
    label: string;
  };
  [ProductCategoryFEnum.Cables]: { id: string; value: string; label: string };
};

export type SupportedCategoryType = {
  value: string;
  id: string;
};

export type ValueMappingType = { [key: string]: string };
